<template>
    <div class="btn-box" ref="btn">
        <div class="btn-menu" @click="handleMenu">
            <img src="@/assets/handleMenu.svg" alt="">
        </div>
    </div>
    
    <div class="lay-menu" ref="menu">
        <ul class="menu">
            <li v-for="item in menuList.options" :key="item.id" :class="{'active': item.link == route.path }" @click="changeLink(item.link)">{{ item.title }}</li>
        </ul>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
const menu = ref(null)
const btn = ref(null)

const route = useRoute()
const router = useRouter()

const menuList = ref({
    options: [
        {id: '1', title: '首页', link: '/'},
        {id: '2', title: '邮箱报价', link: '/price'},
        {id: '3', title: '用户管理', link: '/user'}
    ]
})


const changeLink = (link) => {
    if(window.innerWidth < 768)
        handleMenu()
    router.push(link)
}

const handleMenu = () => {
    menu.value.classList.toggle('disappear')
    btn.value.classList.toggle('disappear')
}

onMounted(() => {
    if(window.innerWidth < 768){
        handleMenu()
    }
})

</script>

<style scoped>


.lay-menu{
    width: 256px;
    height: 100vh;
    position: sticky;
    top: 0;
    background-color: #fefefe;
    transition: width 0.2s ease-in-out;
    overflow: hidden;
    flex: 0 0 auto;
    z-index: 100;
}

.btn-box{
    width: 256px;
    height: 100vh;
    position: fixed;
    top: 0;
    transition: width 0.2s ease-in-out;
    flex: 0 0 auto;
    z-index: 10;
}

.btn-menu{
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 10px 0 0 0;
    background-color: #1677FF;
    padding: 6px 20px 6px 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    user-select: none;
    transform: translateX(100%);
    transition: all 0.2s ease-in-out;
}

.btn-menu img{
    width: 16px;
    height: 16px;
}

.menu{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    list-style: none;
    padding: 3px 4px;
}

.menu > li{
    padding: 10px 20px;
    margin: 4px 0;
    user-select: none;
    cursor: pointer;
    color: #b0b0b0;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    white-space: nowrap;
}

.menu > li:hover{
    color: #000C17;
}

.active{
    background-color: #1677FF;
    color: #fff!important;
}

.logo{
    height: 30px;
    margin: 10px;
}


@media (max-width: 768px) {
    .lay-menu,
    .btn-box{
        position: fixed;
    }
}

.disappear{
    width: 0;
}
</style>