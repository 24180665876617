import { createRouter, createWebHashHistory } from "vue-router"



const routes = [
    {
        path: '/',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/price',
        component: () => import('@/views/Price.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})


export default router